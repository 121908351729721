<template>
	<div>
		<top :windowWidth="windowWidth" :windowHeight="windowHeight"></top>
		<right v-if="windowWidth>=1200"></right>
		<total-ratio :windowWidth="windowWidth" :windowHeight="windowHeight"></total-ratio>
		<workshop :windowWidth="windowWidth" :windowHeight="windowHeight"></workshop>
		<bottom v-if="windowWidth>=1200"></bottom>
	</div>
</template>

<script>
	import top from '@/components/top/index.vue';
	import right from '@/components/right/index.vue';
	import totalRatio from './components/displayFactory/totalRatio.vue';
	import workshop from './components/displayFactory/workshop.vue';
	import bottom from '@/components/bottom/index.vue';
	export default {
		components:{
			top,
			totalRatio,
			workshop,
			bottom,
			right
		},
		data() {
			return {
				// 浏览器窗口宽度高度
				windowWidth: 0,
				windowHeight: 0,
			}
		},
		
		created() {
			
		},
		
		mounted() {
			let that = this;
			that.windowWidth = document.body.clientWidth;
			that.windowHeight = document.body.clientHeight;
			window.onresize = () => {
				return (() => {
					this.windowWidth = document.body.clientWidth;
					this.windowHeight = document.body.clientHeight;
				})();
			};
		},
		
		watch:{
			
		},
		
		computed:{
			
		},
		
		methods:{
			
		}
	}
</script>

<style>
</style>