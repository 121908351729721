import { render, staticRenderFns } from "./totalRatio.vue?vue&type=template&id=671d6b7d&scoped=true&"
import script from "./totalRatio.vue?vue&type=script&lang=js&"
export * from "./totalRatio.vue?vue&type=script&lang=js&"
import style0 from "./totalRatio.vue?vue&type=style&index=0&id=671d6b7d&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "671d6b7d",
  null
  
)

export default component.exports