<template>
	<div>
		<div class="totalRatio" :style="'background-image:' + `url(${img})`" v-if="windowWidth>=1200">
			<div class="tr_title">{{$t('factoryDisplay.displayFactory.title')}}</div>
			<i class="tr_line"></i>
			
			<div class="tr_contentDiv">
				<div class="tr_cd_left">
					<el-image class="tr_cd_le_img" :src="img2" :fit="'cover'"></el-image>
					
					<div class="tr_cd_le_titleDiv">
						<div class="tr_cd_le_td_text">{{$t('factoryDisplay.displayFactory.text')}}</div>
						<div class="tr_cd_le_td_button">
							{{$t('factoryDisplay.displayFactory.text2')}}
						</div>
					</div>
				</div>
				
				<div class="tr_cd_right">
					<div class="tr_cd_ri_top">
						<el-image class="tr_cd_ri_to_img" :src="img3" :fit="'cover'"></el-image>
					</div>
					
					<div class="tr_cd_ri_bottom">
						<el-image class="tr_cd_ri_bt_img" :src="img4" :fit="'cover'"></el-image>
					</div>
				</div>
			</div>
		</div>
		
		<div class="totalRatio2" :style="'background-image:' + `url(${img})`" v-if="windowWidth<=1199">
			<div class="tr_title">{{$t('factoryDisplay.displayFactory.title')}}</div>
			<i class="tr_line"></i>
			
			<div class="tr_contentDiv">
				<div class="tr_cd_left">
					<el-image class="tr_cd_le_img" :src="img2" :fit="'cover'"></el-image>
					
					<div class="tr_cd_le_titleDiv">
						<div class="tr_cd_le_td_text">{{$t('factoryDisplay.displayFactory.text')}}</div>
						<div class="tr_cd_le_td_button">
							{{$t('factoryDisplay.displayFactory.text2')}}
						</div>
					</div>
				</div>
				
				<div class="tr_cd_right">
					<div class="tr_cd_ri_top">
						<el-image class="tr_cd_ri_to_img" :src="img3" :fit="'cover'"></el-image>
					</div>
					
					<div class="tr_cd_ri_bottom">
						<el-image class="tr_cd_ri_bt_img" :src="img4" :fit="'cover'"></el-image>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		props: {
			//宽
			windowWidth: {
				type: Number,
				default: 0
			},
			//高
			windowHeight: {
				type: Number,
				default: 0
			},
		},
		data() {
			return {
				img: process.env.VUE_APP_OSS_URL + '/factoryDisplay/factory-show@bg.jpg',
				img2: process.env.VUE_APP_OSS_URL + '/factoryDisplay/environment1.jpg',
				img3: process.env.VUE_APP_OSS_URL + '/factoryDisplay/adout2.jpg',
				img4: process.env.VUE_APP_OSS_URL + '/factoryDisplay/adout3.jpg',
			}
		},
		
		created() {
			
		},
		
		mounted() {
			
		},
		
		watch:{
			
		},
		
		computed:{
			
		},
		
		methods:{
			
		}
	}
</script>

<style lang="less" scoped>
	
	@media only screen and (min-width:1920px) {
		.totalRatio{
			padding: 40px 200px;
			width: 100%;
			
			.tr_title{
				text-align: center;
				font-size: 32px;
				color: #333333;
			}
			
			.tr_line{
				display: block;
				width: 81px;
				height: 7px;
				margin: 0 auto;
				margin-top: 10px;
				border-radius: 10px;
				background-color: #eacaa1;
			}
			
			.tr_contentDiv{
				width: 100%;
				display: flex;
				margin-top: 50px;
				border-radius: 20px;
				
				.tr_cd_left{
					width: 59%;
					margin-right: 1%;
					position: relative;
					
					.tr_cd_le_img{
						width: 100%;
						border-radius: 20px;
						height: 500px;
					}
					
					.tr_cd_le_titleDiv{
						position: absolute;
						height: 50px;
						left: 0;
						right: 0;
						bottom: 0;
						line-height: 50px;
						padding: 0 30px;
						width: 100%;
						background-image: linear-gradient(to right, #56575b, #666769);
						border-bottom-left-radius: 20px;
						border-bottom-right-radius: 20px;
						
						.tr_cd_le_td_text{
							display: inline-block;
							font-size: 16px;
							color: #fff;
						}
						
						.tr_cd_le_td_button{
							float: right;
							width: 120px;
							height: 32px;
							line-height: 32px;
							font-size: 12px;
							font-weight: bold;
							margin: 10px 0 0 0;
							color: #222;
							border-radius: 40px;
							background-image: linear-gradient(to right, #e4c79f, #d6ae7b);
							text-align: center;
						}
					}
				}
				
				.tr_cd_right{
					width: 40%;
					
					.tr_cd_ri_top{
						width: 100%;
						margin-bottom: 10px;
						
						.tr_cd_ri_to_img{
							width: 100%;
							border-radius: 20px;
							height: 240px;
						}
					}
					
					.tr_cd_ri_bottom{
						width: 100%;
						
						.tr_cd_ri_bt_img{
							width: 100%;
							border-radius: 20px;
							height: 250px;
						}
					}
				}
			}
		}
	}
	
	@media only screen and (max-width:1920px) {
		.totalRatio{
			padding: 40px 200px;
			width: 100%;
			
			.tr_title{
				text-align: center;
				font-size: 32px;
				color: #333333;
			}
			
			.tr_line{
				display: block;
				width: 81px;
				height: 7px;
				margin: 0 auto;
				margin-top: 10px;
				border-radius: 10px;
				background-color: #eacaa1;
			}
			
			.tr_contentDiv{
				width: 100%;
				display: flex;
				margin-top: 50px;
				border-radius: 20px;
				
				.tr_cd_left{
					width: 59%;
					margin-right: 1%;
					position: relative;
					
					.tr_cd_le_img{
						width: 100%;
						border-radius: 20px;
						height: 500px;
					}
					
					.tr_cd_le_titleDiv{
						position: absolute;
						height: 50px;
						left: 0;
						right: 0;
						bottom: 0;
						line-height: 50px;
						padding: 0 30px;
						width: 100%;
						background-image: linear-gradient(to right, #56575b, #666769);
						border-bottom-left-radius: 20px;
						border-bottom-right-radius: 20px;
						
						.tr_cd_le_td_text{
							display: inline-block;
							font-size: 16px;
							color: #fff;
						}
						
						.tr_cd_le_td_button{
							float: right;
							width: 120px;
							height: 32px;
							line-height: 32px;
							font-size: 12px;
							font-weight: bold;
							margin: 10px 0 0 0;
							color: #222;
							border-radius: 40px;
							background-image: linear-gradient(to right, #e4c79f, #d6ae7b);
							text-align: center;
						}
					}
				}
				
				.tr_cd_right{
					width: 40%;
					
					.tr_cd_ri_top{
						width: 100%;
						margin-bottom: 10px;
						
						.tr_cd_ri_to_img{
							width: 100%;
							border-radius: 20px;
							height: 240px;
						}
					}
					
					.tr_cd_ri_bottom{
						width: 100%;
						
						.tr_cd_ri_bt_img{
							width: 100%;
							border-radius: 20px;
							height: 250px;
						}
					}
				}
			}
		}
	}
	
	@media only screen and (max-width:1919px) and (min-width:1440px) {
		.totalRatio{
			padding: 40px 140px;
			width: 100%;
			
			.tr_title{
				text-align: center;
				font-size: 32px;
				color: #333333;
			}
			
			.tr_line{
				display: block;
				width: 81px;
				height: 7px;
				margin: 0 auto;
				margin-top: 10px;
				border-radius: 10px;
				background-color: #eacaa1;
			}
			
			.tr_contentDiv{
				width: 100%;
				display: flex;
				margin-top: 50px;
				border-radius: 20px;
				
				.tr_cd_left{
					width: 59%;
					margin-right: 1%;
					position: relative;
					
					.tr_cd_le_img{
						width: 100%;
						border-radius: 20px;
						height: 500px;
					}
					
					.tr_cd_le_titleDiv{
						position: absolute;
						height: 50px;
						left: 0;
						right: 0;
						bottom: 0;
						line-height: 50px;
						padding: 0 30px;
						width: 100%;
						background-image: linear-gradient(to right, #56575b, #666769);
						border-bottom-left-radius: 20px;
						border-bottom-right-radius: 20px;
						
						.tr_cd_le_td_text{
							display: inline-block;
							font-size: 16px;
							color: #fff;
						}
						
						.tr_cd_le_td_button{
							float: right;
							width: 120px;
							height: 32px;
							line-height: 32px;
							font-size: 12px;
							font-weight: bold;
							margin: 10px 0 0 0;
							color: #222;
							border-radius: 40px;
							background-image: linear-gradient(to right, #e4c79f, #d6ae7b);
							text-align: center;
						}
					}
				}
				
				.tr_cd_right{
					width: 40%;
					
					.tr_cd_ri_top{
						width: 100%;
						margin-bottom: 10px;
						
						.tr_cd_ri_to_img{
							width: 100%;
							border-radius: 20px;
							height: 240px;
						}
					}
					
					.tr_cd_ri_bottom{
						width: 100%;
						
						.tr_cd_ri_bt_img{
							width: 100%;
							border-radius: 20px;
							height: 250px;
						}
					}
				}
			}
		}
	}
	
	@media only screen and (max-width:1439px) and (min-width:1200px) {
		.totalRatio{
			padding: 40px 30px;
			width: 100%;
			
			.tr_title{
				text-align: center;
				font-size: 32px;
				color: #333333;
			}
			
			.tr_line{
				display: block;
				width: 81px;
				height: 7px;
				margin: 0 auto;
				margin-top: 10px;
				border-radius: 10px;
				background-color: #eacaa1;
			}
			
			.tr_contentDiv{
				width: 100%;
				display: flex;
				margin-top: 50px;
				border-radius: 20px;
				
				.tr_cd_left{
					width: 59%;
					margin-right: 1%;
					position: relative;
					
					.tr_cd_le_img{
						width: 100%;
						border-radius: 20px;
						height: 500px;
					}
					
					.tr_cd_le_titleDiv{
						position: absolute;
						height: 50px;
						left: 0;
						right: 0;
						bottom: 0;
						line-height: 50px;
						padding: 0 30px;
						width: 100%;
						background-image: linear-gradient(to right, #56575b, #666769);
						border-bottom-left-radius: 20px;
						border-bottom-right-radius: 20px;
						
						.tr_cd_le_td_text{
							display: inline-block;
							font-size: 16px;
							color: #fff;
						}
						
						.tr_cd_le_td_button{
							float: right;
							width: 120px;
							height: 32px;
							line-height: 32px;
							font-size: 12px;
							font-weight: bold;
							margin: 10px 0 0 0;
							color: #222;
							border-radius: 40px;
							background-image: linear-gradient(to right, #e4c79f, #d6ae7b);
							text-align: center;
						}
					}
				}
				
				.tr_cd_right{
					width: 40%;
					
					.tr_cd_ri_top{
						width: 100%;
						margin-bottom: 10px;
						
						.tr_cd_ri_to_img{
							width: 100%;
							border-radius: 20px;
							height: 240px;
						}
					}
					
					.tr_cd_ri_bottom{
						width: 100%;
						
						.tr_cd_ri_bt_img{
							width: 100%;
							border-radius: 20px;
							height: 250px;
						}
					}
				}
			}
		}
	}
	
	@media only screen and (max-width:1199px) and (min-width:768px) {
		.totalRatio2{
			padding: 100px 30px 40px 30px;
			width: 100%;
			
			.tr_title{
				text-align: center;
				font-size: 21px;
				color: #333333;
			}
			
			.tr_line{
				display: block;
				width: 81px;
				height: 7px;
				margin: 0 auto;
				margin-top: 10px;
				border-radius: 10px;
				background-color: #eacaa1;
			}
			
			.tr_contentDiv{
				width: 100%;
				margin-top: 50px;
				border-radius: 20px;
				
				.tr_cd_left{
					width: 100%;
					position: relative;
					margin-bottom: 10px;
					
					.tr_cd_le_img{
						width: 100%;
						border-radius: 20px;
						height: 500px;
					}
					
					.tr_cd_le_titleDiv{
						position: absolute;
						height: 50px;
						left: 0;
						right: 0;
						bottom: 0;
						line-height: 50px;
						padding: 0 30px;
						width: 100%;
						background-image: linear-gradient(to right, #56575b, #666769);
						border-bottom-left-radius: 20px;
						border-bottom-right-radius: 20px;
						
						.tr_cd_le_td_text{
							display: inline-block;
							font-size: 16px;
							color: #fff;
						}
						
						.tr_cd_le_td_button{
							float: right;
							width: 120px;
							height: 32px;
							line-height: 32px;
							font-size: 12px;
							font-weight: bold;
							margin: 10px 0 0 0;
							color: #222;
							border-radius: 40px;
							background-image: linear-gradient(to right, #e4c79f, #d6ae7b);
							text-align: center;
						}
					}
				}
				
				.tr_cd_right{
					width: 100%;
					
					.tr_cd_ri_top{
						width: 100%;
						margin-bottom: 10px;
						
						.tr_cd_ri_to_img{
							width: 100%;
							border-radius: 20px;
							height: 240px;
						}
					}
					
					.tr_cd_ri_bottom{
						width: 100%;
						
						.tr_cd_ri_bt_img{
							width: 100%;
							border-radius: 20px;
							height: 250px;
						}
					}
				}
			}
		}
	}
	
	@media only screen and (max-width:767px) {
		.totalRatio2{
			padding: 100px 30px 40px 30px;
			width: 100%;
			
			.tr_title{
				text-align: center;
				font-size: 21px;
				color: #333333;
			}
			
			.tr_line{
				display: block;
				width: 81px;
				height: 7px;
				margin: 0 auto;
				margin-top: 10px;
				border-radius: 10px;
				background-color: #eacaa1;
			}
			
			.tr_contentDiv{
				width: 100%;
				margin-top: 50px;
				border-radius: 20px;
				
				.tr_cd_left{
					width: 100%;
					position: relative;
					margin-bottom: 10px;
					
					.tr_cd_le_img{
						width: 100%;
						border-radius: 20px;
						height: 500px;
					}
					
					.tr_cd_le_titleDiv{
						position: absolute;
						height: 50px;
						left: 0;
						right: 0;
						bottom: 0;
						line-height: 50px;
						padding: 0 30px;
						width: 100%;
						background-image: linear-gradient(to right, #56575b, #666769);
						border-bottom-left-radius: 20px;
						border-bottom-right-radius: 20px;
						
						.tr_cd_le_td_text{
							display: inline-block;
							font-size: 16px;
							color: #fff;
						}
						
						.tr_cd_le_td_button{
							float: right;
							width: 120px;
							height: 32px;
							line-height: 32px;
							font-size: 12px;
							font-weight: bold;
							margin: 10px 0 0 0;
							color: #222;
							border-radius: 40px;
							background-image: linear-gradient(to right, #e4c79f, #d6ae7b);
							text-align: center;
						}
					}
				}
				
				.tr_cd_right{
					width: 100%;
					
					.tr_cd_ri_top{
						width: 100%;
						margin-bottom: 10px;
						
						.tr_cd_ri_to_img{
							width: 100%;
							border-radius: 20px;
							height: 240px;
						}
					}
					
					.tr_cd_ri_bottom{
						width: 100%;
						
						.tr_cd_ri_bt_img{
							width: 100%;
							border-radius: 20px;
							height: 250px;
						}
					}
				}
			}
		}
	}
	
</style>