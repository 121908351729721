<template>
	<div>
		<div class="workshopTitle">
			<div class="ws_title">{{$t('factoryDisplay.displayFactory.workshop.title')}}</div>
			<i class="ws_line"></i>
			
			<div class="ws_bg"
				:style="'background-image:' + `url(${img})`">
			</div>
		</div>
		
		<div class="workshopContent">
			<div style="" v-for="(item,index) in $t('factoryDisplay.displayFactory.workshop.list')" :key="index">
				<div class="wc_left">
					<div class="wc_lf_nameDiv">
						<img :src="item.icon" />
						<div class="wc_lf_nd_text">{{item.title}}</div>
					</div>
					<div class="wc_lf_nd_content" style="font-size: 14px;margin-top: 20px;text-align: center;">
						{{item.text}}
					</div>
				</div>
				<div class="wc_right">
					<div class="wc_rg_imgListDiv" v-for="(item2,index2) in item.imgList" :key="index">
						<el-image style="height: 150px;" :src="item2.img" :fit="'cover'"></el-image>
						<div class="wc_rg_ild_text">{{item2.name}}</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		props: {
			//宽
			windowWidth: {
				type: Number,
				default: 0
			},
			//高
			windowHeight: {
				type: Number,
				default: 0
			},
		},
		data() {
			return {
				img: process.env.VUE_APP_OSS_URL + '/factoryDisplay/why-us-bg.jpg',
			}
		},
		
		created() {
			
		},
		
		mounted() {
			
		},
		
		watch:{
			
		},
		
		computed:{
			
		},
		
		methods:{
			
		}
	}
</script>

<style lang="less" scoped>
	
	@media only screen and (min-width:1920px) {
		.workshopTitle{
			padding: 40px 200px;
			position: relative;
			z-index: 2;
			
			.ws_title{
				text-align: center;
				font-size: 32px;
				color: #333333;
				position: relative;
				z-index: 2;
				color: #fff;
			}
			
			.ws_line{
				display: block;
				width: 81px;
				height: 7px;
				margin: 0 auto;
				margin-top: 10px;
				border-radius: 10px;
				background-color: #eacaa1;
				position: relative;
				z-index: 2;
			}
			
			.ws_bg{
				position: absolute;
				width: 100%;
				top: 0;
				left: 0;
				background-size:100%;
				background-attachment: fixed;
				z-index: 1;
				height: 100%;
			}
		}
		
		.workshopContent{
			padding: 40px 200px;
			
			.wc_left{
				padding: 20px;
				
				.wc_lf_nameDiv{
					display: flex;
					justify-content: center;
					
					.wc_lf_nd_text{
						font-size: 32px;
						color: #333333;
						padding-left: 10px;
					}
				}
			}
			
			.wc_right{
				padding: 20px;
				display: flex;
				flex-wrap: wrap;
				justify-content: center;
				border-bottom: 1px solid #f3f3f3;
				
				.wc_rg_imgListDiv{
					width: 200px;
					text-align: center;
					margin-right: 20px;
					
					.wc_rg_ild_text{
						padding-top: 10px;
						padding-bottom: 10px;
					}
				}
			}
		}
	}
	
	@media only screen and (max-width:1920px) {
		.workshopTitle{
			padding: 40px 200px;
			position: relative;
			z-index: 2;
			
			.ws_title{
				text-align: center;
				font-size: 32px;
				color: #333333;
				position: relative;
				z-index: 2;
				color: #fff;
			}
			
			.ws_line{
				display: block;
				width: 81px;
				height: 7px;
				margin: 0 auto;
				margin-top: 10px;
				border-radius: 10px;
				background-color: #eacaa1;
				position: relative;
				z-index: 2;
			}
			
			.ws_bg{
				position: absolute;
				width: 100%;
				top: 0;
				left: 0;
				background-size:100%;
				background-attachment: fixed;
				z-index: 1;
				height: 100%;
			}
		}
		
		.workshopContent{
			padding: 40px 200px;
			
			.wc_left{
				padding: 20px;
				
				.wc_lf_nameDiv{
					display: flex;
					justify-content: center;
					
					.wc_lf_nd_text{
						font-size: 32px;
						color: #333333;
						padding-left: 10px;
					}
				}
			}
			
			.wc_right{
				padding: 20px;
				display: flex;
				flex-wrap: wrap;
				justify-content: center;
				border-bottom: 1px solid #f3f3f3;
				
				.wc_rg_imgListDiv{
					width: 200px;
					text-align: center;
					margin-right: 20px;
					
					.wc_rg_ild_text{
						padding-top: 10px;
						padding-bottom: 10px;
					}
				}
			}
		}
	}
	
	@media only screen and (max-width:1919px) and (min-width:1440px) {
		.workshopTitle{
			padding: 40px 140px;
			position: relative;
			z-index: 2;
			
			.ws_title{
				text-align: center;
				font-size: 32px;
				color: #333333;
				position: relative;
				z-index: 2;
				color: #fff;
			}
			
			.ws_line{
				display: block;
				width: 81px;
				height: 7px;
				margin: 0 auto;
				margin-top: 10px;
				border-radius: 10px;
				background-color: #eacaa1;
				position: relative;
				z-index: 2;
			}
			
			.ws_bg{
				position: absolute;
				width: 100%;
				top: 0;
				left: 0;
				background-size:100%;
				background-attachment: fixed;
				z-index: 1;
				height: 100%;
			}
		}
		
		.workshopContent{
			padding: 40px 140px;
			
			.wc_left{
				padding: 20px;
				
				.wc_lf_nameDiv{
					display: flex;
					justify-content: center;
					
					.wc_lf_nd_text{
						font-size: 32px;
						color: #333333;
						padding-left: 10px;
					}
				}
			}
			
			.wc_right{
				padding: 20px;
				display: flex;
				flex-wrap: wrap;
				justify-content: center;
				border-bottom: 1px solid #f3f3f3;
				
				.wc_rg_imgListDiv{
					width: 200px;
					text-align: center;
					margin-right: 20px;
					
					.wc_rg_ild_text{
						padding-top: 10px;
						padding-bottom: 10px;
					}
				}
			}
		}
	}
	
	@media only screen and (max-width:1439px) and (min-width:1200px) {
		.workshopTitle{
			padding: 40px 30px;
			position: relative;
			z-index: 2;
			
			.ws_title{
				text-align: center;
				font-size: 32px;
				color: #333333;
				position: relative;
				z-index: 2;
				color: #fff;
			}
			
			.ws_line{
				display: block;
				width: 81px;
				height: 7px;
				margin: 0 auto;
				margin-top: 10px;
				border-radius: 10px;
				background-color: #eacaa1;
				position: relative;
				z-index: 2;
			}
			
			.ws_bg{
				position: absolute;
				width: 100%;
				top: 0;
				left: 0;
				background-size:100%;
				background-attachment: fixed;
				z-index: 1;
				height: 100%;
			}
		}
		
		.workshopContent{
			padding: 40px 30px;
			
			.wc_left{
				padding: 20px;
				
				.wc_lf_nameDiv{
					display: flex;
					justify-content: center;
					
					.wc_lf_nd_text{
						font-size: 32px;
						color: #333333;
						padding-left: 10px;
					}
				}
			}
			
			.wc_right{
				padding: 20px;
				display: flex;
				flex-wrap: wrap;
				justify-content: center;
				border-bottom: 1px solid #f3f3f3;
				
				.wc_rg_imgListDiv{
					width: 200px;
					text-align: center;
					margin-right: 20px;
					
					.wc_rg_ild_text{
						padding-top: 10px;
						padding-bottom: 10px;
					}
				}
			}
		}
	}
	
	@media only screen and (max-width:1199px) and (min-width:768px) {
		.workshopTitle{
			padding: 40px 30px;
			position: relative;
			z-index: 2;
			
			.ws_title{
				text-align: center;
				font-size: 21px;
				color: #333333;
				position: relative;
				z-index: 2;
				color: #fff;
			}
			
			.ws_line{
				display: block;
				width: 81px;
				height: 7px;
				margin: 0 auto;
				margin-top: 10px;
				border-radius: 10px;
				background-color: #eacaa1;
				position: relative;
				z-index: 2;
			}
			
			.ws_bg{
				position: absolute;
				width: 100%;
				top: 0;
				left: 0;
				background-size:100%;
				background-attachment: fixed;
				z-index: 1;
				height: 100%;
			}
		}
		
		.workshopContent{
			padding: 40px 30px;
			
			.wc_left{
				padding: 20px;
				
				.wc_lf_nameDiv{
					display: flex;
					flex-wrap: wrap;
					justify-content: center;
					align-items: center;
					
					.wc_lf_nd_text{
						font-size: 21px;
						color: #333333;
						padding-left: 10px;
					}
				}
			}
			
			.wc_right{
				padding: 20px;
				display: flex;
				flex-wrap: wrap;
				justify-content: center;
				border-bottom: 1px solid #f3f3f3;
				
				.wc_rg_imgListDiv{
					width: 200px;
					text-align: center;
					margin-right: 20px;
					
					.wc_rg_ild_text{
						padding-top: 10px;
						padding-bottom: 10px;
					}
				}
			}
		}
	}
	
	@media only screen and (max-width:767px) {
		.workshopTitle{
			padding: 40px 30px;
			position: relative;
			z-index: 2;
			
			.ws_title{
				text-align: center;
				font-size: 21px;
				color: #333333;
				position: relative;
				z-index: 2;
				color: #fff;
			}
			
			.ws_line{
				display: block;
				width: 81px;
				height: 7px;
				margin: 0 auto;
				margin-top: 10px;
				border-radius: 10px;
				background-color: #eacaa1;
				position: relative;
				z-index: 2;
			}
			
			.ws_bg{
				position: absolute;
				width: 100%;
				top: 0;
				left: 0;
				background-size:100%;
				background-attachment: fixed;
				z-index: 1;
				height: 100%;
			}
		}
		
		.workshopContent{
			padding: 40px 30px;
			
			.wc_left{
				padding: 20px;
				
				.wc_lf_nameDiv{
					display: flex;
					flex-wrap: wrap;
					justify-content: center;
					align-items: center;
					
					.wc_lf_nd_text{
						font-size: 21px;
						color: #333333;
						padding-left: 10px;
					}
				}
			}
			
			.wc_right{
				padding: 20px;
				display: flex;
				flex-wrap: wrap;
				justify-content: center;
				border-bottom: 1px solid #f3f3f3;
				
				.wc_rg_imgListDiv{
					width: 200px;
					text-align: center;
					margin-right: 20px;
					
					.wc_rg_ild_text{
						padding-top: 10px;
						padding-bottom: 10px;
					}
				}
			}
		}
	}
	
</style>